import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import {BgImageOverlay, BgImageContainer} from "../styled/container"
import HTML2React from 'html2react'
import { EffectFade, Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide} from 'swiper/react';
import { device, size } from '../styled/devices';
import { window, document, exists } from 'browser-monads';
import ImageLeft from "../components/imageleft"
import CTA from "../components/cta"
import ImageRight from "../components/imageright"
import Team from "../components/team"
import LargeImage from "../components/largeimage"
import Card from "../components/card"
import Seo  from "../components/seo"
import {Container, RowContainer} from "../styled/container"
import Loading from "../components/loading"
// import AOS from 'aos';
// import 'aos/dist/aos.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { faShower } from "@fortawesome/free-solid-svg-icons"

const Page = ({ data }) => {
    // useEffect(() => {
    //     AOS.init({duration: 1200,});
    // })
   
  console.log("Data", data)
 
    const style = {
        backgroundImage: 'url(' + data.wpPage.featuredImage.node.sourceUrl + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'

    }
    const sections = JSON.parse(data.wpPage.contentSections)
    const gallery = JSON.parse(data.wpPage.customGallery)
    let cards = [];
    let cardsAdded = false;
    for (let i = 0; i < sections.length; i++) {
        if (sections[i].sectionLayout === 'card') {
               cards.push(sections[i])
            }
        }
    
    const cardFunc = () => {
        return(
            <RowContainer>
                {cards.map((section) => (
                <Card dark={section.sectionStyle} content={section.sectionContent} image={section.sectionImageUrl}/>
                ))}
            </RowContainer>
            )
            };
        
    
    console.log("Sections", cards)
    let bgImage = '';
    let output = [];
    let value = '';
    cardsAdded = false;
    for (let i = 0; i < sections.length; i++) {
        if (sections[i].sectionLayout === 'img-right') {
            value = <ImageRight aos='fade-down' dark={sections[i].sectionStyle} content={sections[i].sectionContent} image={sections[i].sectionImageUrl}/>;
            
            output.push(value);
            console.log("right", value)
        }
        if (sections[i].sectionLayout === 'img-left') {
            value = <ImageLeft aos='fade-down' dark={sections[i].sectionStyle} content={sections[i].sectionContent} image={sections[i].sectionImageUrl}/>;
           
            output.push(value);
            console.log("left", value)
        }
        if (sections[i].sectionLayout === 'card' && cardsAdded == false) {
            value = cardFunc()
            output.push(value)
            cardsAdded = true;
        }
        if (sections[i].sectionLayout === 'cta') {
            value = <CTA aos='fade-left' dark={sections[i].sectionStyle} content={sections[i].sectionContent} image={sections[i].sectionImageUrl} />
            output.push(value)
        }
        
    }
    

  return (
    <Layout>
        
            {gallery === '' ?
                <FeaturedImage style={style}>
                    <BgImageContainer>
                        <h1><div dangerouslySetInnerHTML={{ __html: data.wpPage.content }} /></h1>
                    </BgImageContainer>

                    <div className="custom-shape-divider-bottom-1667375169">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                             preserveAspectRatio="none">
                            <path d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z"
                                  className="shape-fill"></path>
                        </svg>
                    </div>
                </FeaturedImage>
                :
                <FeaturedSwiper>

                <Swiper className="swiper-feature"
                        modules={[EffectFade, Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation
                        loop={true}
                       
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                          }}
                        pagination={{clickable: true}}

                >
                    {gallery.map((image) => (
                        bgImage = {
                            backgroundImage: 'url(' + image + ')',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',

                        },
                            <SwiperSlide style={bgImage}>
                                <BgImageContainer style={{zIndex: "3"}}>
                                    <h1><div dangerouslySetInnerHTML={{ __html: data.wpPage.content }} /></h1>
                                </BgImageContainer>
                            </SwiperSlide>
                    ))
                    }

                </Swiper>
            </FeaturedSwiper>
                }
        {output != '' ?
            output.map((item) => {
                return (item)
            })
    :null}

    </Layout>
  )
}
export const pageQuery = graphql`
query MyQuery {
  wpPage(isFrontPage: {}) {
    id
    title
    content
    contentSections
    featuredImage {
      node {
        sourceUrl
      }
    }
    customGallery
  }
}


  `
  export default Page

  const FeaturedSwiper = styled.div`
  height:100vh;
  width:100%;
  .swiper-feature {
  height:100vh;
  width:100%;
  }
  ` 

const FeaturedImage = styled.div`
position:relative;
width: 100%;
height: 100vh;

.custom-shape-divider-bottom-1667375169 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1667375169 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 350px;
}

.custom-shape-divider-bottom-1667375169 .shape-fill {
    fill: #FFFFFF;
}
`
export const Head = () => (
    // <>
    //   <title>Hello World</title>
    //   <meta name="description" content="Hello World" />
    // </>
    <Seo title="home"/>
  )